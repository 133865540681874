var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        width: _vm.width,
        placement: "bottom",
        trigger: "hover",
        "popper-class": "commonPopoverForPersonalCenter",
        "visible-arrow": false,
        offset: -25
      },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return (() => {}).apply(null, arguments)
        }
      }
    },
    [
      _vm.operatingMenu.length !== 0
        ? _c(
            "div",
            { staticClass: "operatingMenuBox", class: _vm.mode },
            _vm._l(_vm.operatingMenu, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "operatingMenuIconBox",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.selectThisMenuItem(index, item)
                    }
                  }
                },
                [
                  item.iconClassName
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: item.tooltipContent,
                                placement: "top"
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "iconfont_Me iconStyle",
                                class: item.iconClassName
                              })
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.name == "shield"
                    ? _c(
                        "div",
                        { staticClass: "shield_style" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: item.tooltipContent,
                                placement: "top"
                              }
                            },
                            [
                              _c("div", { staticClass: "blacklistIcon" }, [
                                _c("span", {
                                  staticClass:
                                    "iconfont_Me iconStyle icon-a-zu9816"
                                })
                              ])
                            ]
                          ),
                          _c("el-switch", {
                            on: {
                              change: function($event) {
                                return _vm.changeIsInBlackList(item)
                              }
                            },
                            model: {
                              value: _vm.isInBlackList,
                              callback: function($$v) {
                                _vm.isInBlackList = $$v
                              },
                              expression: "isInBlackList"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: item.tooltipContent,
                        placement: "top"
                      }
                    },
                    [
                      item.imageSrc
                        ? _c("img", {
                            style: {
                              width: `${item.imageWidth}px`,
                              height: `${item.imageHeight}px`
                            },
                            attrs: { src: item.imageSrc }
                          })
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._t("default", null, { slot: "reference" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }