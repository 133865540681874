<template>
  <el-popover
    :width="width"
    placement="bottom"
    trigger="hover"
    popper-class="commonPopoverForPersonalCenter"
    :visible-arrow="false"
    :offset="-25"
    @click.stop="() => {}"
  >
    <div
      v-if="operatingMenu.length !== 0"
      class="operatingMenuBox"
      :class="mode"
    >
      <div
        class="operatingMenuIconBox"
        v-for="(item, index) in operatingMenu"
        :key="index"
        @click.stop="selectThisMenuItem(index, item)"
      >
        <div v-if="item.iconClassName">
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.tooltipContent"
            placement="top"
          >
            <span
              class="iconfont_Me iconStyle"
              :class="item.iconClassName"
            ></span>
          </el-tooltip>
        </div>
        <div v-if="item.name == 'shield'" class="shield_style">
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.tooltipContent"
            placement="top"
          >
            <div class="blacklistIcon">
              <span class="iconfont_Me iconStyle icon-a-zu9816"></span>
            </div>
          </el-tooltip>
          <el-switch
            v-model="isInBlackList"
            @change="changeIsInBlackList(item)"
          ></el-switch>
        </div>

        <el-tooltip
          class="item"
          effect="dark"
          :content="item.tooltipContent"
          placement="top"
        >
          <img
            v-if="item.imageSrc"
            :src="item.imageSrc"
            :style="{
              width: `${item.imageWidth}px`,
              height: `${item.imageHeight}px`,
            }"
          />
        </el-tooltip>
      </div>
    </div>
    <slot slot="reference"></slot>
  </el-popover>
</template>
<script>
import * as RESTApi from "@/api/rest";
export default {
  name: "commonPopover",
  props: {
    // 操作菜单数据
    operatingMenu: {
      type: Array,
      default: () => {
        return [];
      },
    },
    width: {
      type: Number,
      default: () => {
        return 80;
      },
    },
    mode: {
      type: String,
      default: () => {
        return "";
      },
    },
    // 需要操作的对象
    operatingItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    shareConfig: {
      type: Object,
      default: () => {
        return {
          // 分享的内容类型(1:帖子,3:用户,4:群)
          share_type: "",
          // 分享的内容Id
          share_id: "",
        };
      },
    },
  },
  data() {
    return {
      // 是否将此人拉入了黑名单
      isInBlackList: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.getBlacklistStatus();
    }, 500);
  },
  methods: {
    // 选择这个操作选项
    selectThisMenuItem(index, item) {
      this.$emit("selectThisMenuItem", index, item, this.operatingItem);
    },
    async changeIsInBlackList() {
      let result = new Object();
      // 如果设置为true，则将用户拉入黑名单
      if (this.isInBlackList) {
        let params = {
          blacklistProviderId: this.shareConfig.share_id,
        };
        result = await RESTApi.joinBlacklist(params);
      } else {
        result = await RESTApi.removeBlacklist(this.shareConfig.share_id);
      }
      if (result.code !== 200) {
        this.$message({
          type: "error",
          message: result.message,
        });
        this.isInBlackList = !this.isInBlackList;
      } else {
        // 通知状态管理器，黑名单需要改变了
        this.$store.commit("setBlackStatus", this.isInBlackList);
        this.$store.commit("setBlackListChangeStatus", true);
      }
    },
    // 判断此人是否在你的黑名单中
    async getBlacklistStatus() {
      if (this.shareConfig.share_id) {
        let result = await RESTApi.getBlacklistStatus([
          this.shareConfig.share_id,
        ]);
        if (result.code == 200 && result.data.data.length !== 0) {
          this.isInBlackList = result.data.data[0].positiveStatus;
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
  },
};
</script>
<style lang="stylus">
.commonPopoverForPersonalCenter {
  // width 80px !important;
  min-width: 80px !important;
  padding: 20px 0 !important;

  .operatingMenuBox {
    width: 18px;
    margin: auto;

    .operatingMenuIconBox {
      width: 100%;
      height: 18px;
      cursor: pointer;
      text-align: center;
      line-height: 18px;
      margin-bottom: 20px;
      font-size:18px;
      &:last-child {
        margin-bottom: 0 !important;
      }
      .iconStyle {
        color: #969696;
      }
    }
  }

  .shield {
    margin: 0;
    padding: 0 20px;
    width: 100%;
    .shield_style {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .blacklistIcon {
        display: flex;
        align-items: center

      }
    }

    .iconStyle {
      font-size: 24px !important;
    }

    .operatingMenuIconBox {
      text-align: left !important;
    }
  }
}
</style>
